import { render, staticRenderFns } from "./yqg-footer.vue?vue&type=template&id=e2e0cf76&scoped=true&"
import script from "./yqg-footer.vue?vue&type=script&lang=js&"
export * from "./yqg-footer.vue?vue&type=script&lang=js&"
import style0 from "./yqg-footer.vue?vue&type=style&index=0&id=e2e0cf76&lang=scss&rel=stylesheet%2Fscss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e2e0cf76",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/agent/workspace/fintopia-web7chz7/fintopia-web-ci/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e2e0cf76')) {
      api.createRecord('e2e0cf76', component.options)
    } else {
      api.reload('e2e0cf76', component.options)
    }
    module.hot.accept("./yqg-footer.vue?vue&type=template&id=e2e0cf76&scoped=true&", function () {
      api.rerender('e2e0cf76', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/app/lingyue-tech/component/yqg-footer/yqg-footer.vue"
export default component.exports